import React, { useState, useEffect } from "react";
import { search } from '../service';

import searchIcon from "../assets/search-icon.png";
import CityBannerDesktop from '../assets/city-banner-desktop.png'
import CityBannerMobile from '../assets/city-banner-mobile.png'
import GooglePlay from '../assets/google-play@3x.png'
import AppStore from '../assets/app-store@3x.png'
import './views.css';
import { useParams } from "react-router";
import Header from "../components/Header";

function SearchView(props) {

  const [minLeft, setMinLeft] = useState(window.innerWidth * 15/100 + 300)

  const calculateTranslateAmount = () => {
    // Calculate the translate amount of the banner
    const bannerWidth = window.innerHeight * 1241 / 950
    return Math.max(0, (window.innerWidth - minLeft) - bannerWidth)
  }

  const [id, setID] = useState("")
  const [translateAmount, setTranslateAmount] = useState(calculateTranslateAmount())
  const [error, setError] = useState("")
  const { gid } = useParams();
  
  const submitID = async (e) => {
    e?.preventDefault()
    const res = await search((id === '') ? gid : id)

    if(res.statusCode === 400){
      setError(res.errorDescription)
    }
    else {
      props.history.push({
        pathname: '/result',
          state: {
            response : res, 
            gxnId: id
          }
      })
    }
  }

  
  if (gid !== undefined) {
      submitID();
  }

  useEffect(() => {
    const handleResize = () => {
      setTranslateAmount(calculateTranslateAmount())
      setMinLeft(window.innerWidth * 15/100 + 300)
    }
    window.addEventListener('resize', handleResize)

    return _ => {
      window.removeEventListener('resize', handleResize)
    }
  })

  return (
    <div className='page-container'>
      <Header home={true} />
      <img className='city-banner-desktop' alt="CityBanner" src={CityBannerDesktop} style={{
        position: 'fixed', 
        height: '100vh', 
        top: '0', 
        left: minLeft + 'px', 
        transform: 'translateX(' + translateAmount + 'px)'
      }}/>

      <img className='city-banner-mobile' src={CityBannerMobile} alt="CityBanner" />

      <form className='search-form' onSubmit={submitID}>
      
        <div className={error ? 'Rectangle-4 error-shadow' : 'Rectangle-4'}>
          <input className="Geoxon-ID" type='text' placeholder="Geoxon Code" onChange={(e) => { setID(e.target.value) }}></input>
          <button className="search-button" type='submit'>
            <img src={searchIcon} className="search-icon" alt="search"></img>
          </button>
          <br/>
        </div>
        <div className='error-message'>{error}</div>
        <span className="guide-text">Let's explore the world!</span>
        <div className='description'>You can create your Geoxon ID now and make any spot on earth - or universe - your home!</div>
        <div className='store-container mt-3'>
          <a href='# '><img className='store-logo me-3' src={GooglePlay} alt="Googleplay-logo"/></a>
          <a href='# '><img className='store-logo' src={AppStore} alt="AppStore-logo"/></a>
        </div>
      </form>
    </div>
  );

}
export default SearchView;