import React from 'react'
import world from "../assets/about-page/about-world.png"
import Footer from '../components/Footer';
import Header from '../components/Header';

const TrustedFarm = () => {
    return (
        <>
            <Header />
            <div className='Landing'>
                <div className="Landing__hero">
                    <div className="container">
                        <h1>Trusted Farm™</h1>
                        <ol className="Landing__description">
                            <li>
                                In today's industrialized and developed world the consumers and farmers live a totally separated lives. The billions of consumers living in the cities have no direct access to thousands of farmers and producers. They have to depend on many intermediaries such as distribution companies, grocery chains etc. The result is that the consumers pay much more than they are supposed to pay while the farmers and producers do not get a fair share of the money being made in the process.
                            </li>
                            <p></p>
                            <li>
                                Trusted Farm is utilizing Geoxon's enriched address and information sharing platform to enable the consumers and the producers to get together and the consumer directly purchases from the producers and the pick-up and delivery is taken care of by the Trusted Delivery Network consisting of Trusted Delivery Agents and partners.
                            </li>
                            <p></p>
                            <li>
                                In today's farming environment small and medium size farmers are in need of affordable third-party farming services to stay profitable in the highly competitive marketplace dominated by giant farming companies. As Trusted Farm, we bring together third-party farming service providers to serve the small and medium size farmers at a reasonable cost. This includes the following farming services: 
                                <p></p>
                                <ul>
                                    <li>Sourcing of specialist farm workers</li>
                                    <li>Field preparation</li>
                                    <li>Soil fertility and composting</li>
                                    <li>Planting</li>
                                    <li>Biocide</li>
                                    <li>Harvesting</li>
                                    <li>Packaging</li>
                                    <li>Warehousing</li>
                                    <li>Shipping and distribution</li>
                                    <li>Marketing</li>
                                </ul> 
                            </li>
                        </ol>
                        <a className="nav-button" href='mailto:hr@geoxon.com'>Contact Us</a>

                        <img className="Landing__world" src={world} alt="" />
                    </div>
                </div>
            </div>
            <Footer />
        </>

    );
}

export default TrustedFarm