import React from 'react'
import world from "../assets/about-page/about-world.png"
import Header from '../components/Header';
import Footer from '../components/Footer';

const TrustedDelivery = () => {
    return (
        <>
            <Header />
            <div className='Landing'>
                <div className="Landing__hero">
                    <div className="container">
                        <h1>Trusted Delivery™</h1>
                        <div className="Landing__description">
                            <p>
                                Trusted Delivery is what we do utilizing Geoxon's Trusted Delivery Network which consists of Trusted Delivery Agents. Utilizing Geoxon's enriched address and information sharing platform we are offering end-to-end pick-up and delivery services through our Trusted Logistics services. Geoxon's technology provides a fully digital end-to-end process which enables full accountability of all parties the pick and delivery of the goods.
                            </p>
                        </div>
                        <a className="nav-button" href='mailto:hr@geoxon.com'>Contact Us</a>

                        <img className="Landing__world" src={world} alt="" />
                    </div>
                </div>
            </div>
            <Footer />
        </>

    );
}

export default TrustedDelivery