import React, {Component} from 'react'
import {Map, Marker, GoogleApiWrapper, InfoWindow} from 'google-maps-react';
import PinBlue from "../assets/pin-blue.png";
import WaypointA from "../assets/waypoint_A.png";
import WaypointB from "../assets/waypoint_B.png";
import WaypointC from "../assets/waypoint_C.png";
 
class MapContainer extends Component {
    state = {
        showingInfoWindow: false,
        activeMarker: {},
        selectedPlace: {},
      };

      onMarkerClick = (props, marker, e) =>
      this.setState({
        selectedPlace: props,
        activeMarker: marker,
        showingInfoWindow: true
      });
  
    onMapClicked = (props) => {
      if (this.state.showingInfoWindow) {
        this.setState({
          showingInfoWindow: false,
          activeMarker: null
        })
      }
    };


    render() {

        const getWayPointIndex = (index) => {
        return index===0? WaypointA:index===1? WaypointB:index===2? WaypointC:PinBlue
        }

        if(!this.props.location){
            return (<div></div>)
        }

        const containerStyle = {
            position: 'relative',  
            width: '100%',
            height: '100%'
        }

        //console.log(this.props.google)

        return (
        <Map 
            containerStyle={containerStyle} 
            google={this.props.google} 
            initialCenter={{
                lat: this.props.location.ylat,
                lng: this.props.location.xlon 
            }}
            zoom={14}>
           
            <Marker icon={{
              url: PinBlue,
                scale: 7,
            }}/>
            {this.props.waypoints?.map((way,index) => 
            <Marker  key={'marker'+index}
                     title={way.description} 
                     name={way.description} 
                     onClick={this.onMarkerClick}
                     position={{lat: way.location.ylat, lng: way.location.xlon}}  
                     icon={{
                        url: getWayPointIndex(index),
                         scale: 7,
                      }}

                     />
            )}
            <InfoWindow marker={this.state.activeMarker}
             visible={this.state.showingInfoWindow}>
            <div>
              <h6>{this.state.selectedPlace.name}</h6>
            </div>
            </InfoWindow>
        </Map>
        );
    }
}
 
export default GoogleApiWrapper({
  apiKey: 'AIzaSyAkOVy6oAnOTSDZL-JH5aDtGJHU8j7Id7w'
})(MapContainer)