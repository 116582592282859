import React from 'react'
import world from "../assets/about-page/about-world.png"
import Header from '../components/Header';
import Footer from '../components/Footer';

const TrustedDeliveryAgent = () => {
    return (
        <>
            <Header />
            <div className='Landing'>
                <div className="Landing__hero">
                    <div className="container">
                        <h1>Trusted Delivery Agent™</h1>
                        <h1>Delivery System</h1>
                        <div className="Landing__description">
                            <p>
                                E-commerce is experiencing unprecedented growth, and this surge directly impacts the parcel delivery industry. Currently, approximately 80% of all parcel delivery volume is generated by e-commerce activities, a percentage that continues to rise. Recent industry analysis indicates that around 53% of all shipping costs are accrued in the last mile of delivery.
                            </p>
                            <p>
                                To address the escalating demand for parcel delivery, centralized delivery methods no longer provide a viable solution for cost reduction and improved service quality in the last mile delivery process. Geoxon® has introduced Trusted Delivery Agent™ delivery system to offer a high-quality, cost-effective solution for the last-mile problem of parcel delivery. Trusted Delivery Agent™ delivery system helps parcel delivery and facilitates arranging for pickup, delivery, storage and transportation of documents, packages, freight and parcels via ground and air carriers. In certain jurisdictions, Trusted Delivery Agent™ delivery system may also facilitate or provide postal delivery services for parcel.
                            </p>
                            <p>
                                At Geoxon, we believe that local delivery is best handled by local partners in the neighborhood. Trusted Delivery Agent™ is a local partner of our delivery system organized under the Geoxon® Enriched Address and Information Sharing System. Whether you run a small business with extra capacity or have available space in your house, becoming a Trusted Delivery Agent™ makes you an essential partner for the parcel delivery industry's last-mile efforts.
                            </p>
                            <p>
                                Trusted Delivery Agents are typically selected from neighborhood local grocery shops or small businesses. Depending on the country and local culture, Trusted Delivery Agents could also be a garage or any other local facility in your neighborhood. Our Trusted Delivery Agents help in arranging for pickup, delivery, storage and transportation of documents, packages, freight and parcels via ground and air carriers.
                            </p>
                            <p>
                                You can easily join the Trusted Delivery Network® by meeting certain criteria and becoming a Trusted Delivery Agent™. All you need is a safe, clean area to store incoming and outgoing parcels. Utilizing your smartphone, you can seamlessly conduct all operations without the need for additional technology investments. By becoming a part of the parcel delivery supply chain, you can start generating income for your business by utilizing your unused capacity.
                            </p>
                            <p>
                                If you have:
                            </p>
                            <ul>
                                <li>A good reputation in your neighborhood</li>
                                <li>A clean and safe business place</li>
                                <li>A positive business history</li>
                                <li>Extra capacity and space available in your business place or house</li>
                            </ul> 
                            <p>
                                You can apply to become a Trusted Delivery Agent™ and start earning money using Geoxon's state-of-the-art technology.
                            </p>
                        </div>
                        <a className="nav-button" href='mailto:hr@geoxon.com'>Contact Us</a>

                        <img className="Landing__world" src={world} alt="" />
                    </div>
                </div>
                <Footer />
            </div>
        </>

    );
}

export default TrustedDeliveryAgent