import React, { useState } from 'react'
import "../styles/Topbar.scss";
import Logo1 from "../assets/geoxon-logo 2_2x.png";
//import Logo2 from "../assets/geoxon-logo-1_3x.png";
import { ReactComponent as Hmbr } from '../assets/hmbr.svg';
import { Link } from 'react-router-dom';


const Header = ({ home }) => {
    const [burger, setBurger] = useState(false);
    const [sub, setSub] = useState(false);

    return (
        <div className={`topbar${home ? ' home' : ''}`}>
            <a href="/">
                <img src={home ? Logo1 : Logo1} alt="Geoxon" className="logo" />
            </a>

            <nav className={burger ? 'open' : ''}>
                <ul className='menu'>
                    <li><Link to="/">Home</Link></li>
                    <li className='has-sub' onClick={() => setSub(!sub)}><Link to={'#'}> Services  </Link>
                        <div className={`menu-sub${sub ? ' open' : ''}`}>
                            <Link to="/services/geoxon">Geoxon™</Link>
                            <Link to="/services/trusted-delivery">Trusted Delivery™</Link>
                            <Link to="/services/trusted-delivery-agent">Trusted Delivery Agent™</Link>
                            <Link to="/services/trusted-delivery-network">Trusted Delivery Network™</Link>
                            <Link to="/services/trusted-logistics">Trusted Logistics™</Link>
                            <Link to="/services/trusted-farm">Trusted Farm™</Link>
                        </div></li>
                    <li> <Link to="/about-us"> About Geoxon®</Link></li>
                </ul>
                <div className='Button--hmbr' onClick={() => setBurger(!burger)}>
                    <Hmbr />
                </div>
            </nav>
        </div>
    )
}

export default Header