import React from "react";
import "../styles/Footer.css";
import GooglePlayBanner from "../assets/google-play@2x.png";
import ApplestoreBanner from "../assets/app-store@2x.png";
import GeoxonLogo from "../assets/logo@2x.png";
import AppLogo from "../assets/geoxon-app@2x.png";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className='footer container-fluid'>
      <div className='fl'>
        <div className='fll'>
          <a href="/">
            <img src={GeoxonLogo} alt="geoxon" style={{ height: "32px" }} />
          </a>
          <div className='vl'></div>
          <img src={AppLogo} alt="geoxon" style={{ height: "32px" }} />
        </div>
        <div className='flr'>
          <img className='mx-1' src={ApplestoreBanner} alt="google play" style={{
            width: '100%', cursor: "pointer", minWidth: "30px", maxWidth: "100px"
          }} />
          <img className='mx-1' src={GooglePlayBanner} alt="google play" style={{
            width: '100%', cursor: "pointer", minWidth: "30px", maxWidth: "100px"
          }} />
        </div>
      </div>
      <div className='fr'>
        <Link className="footer-text mx-2" to="/about-us">About Geoxon</Link>
      </div>
    </div>
  )

}
export default Footer;