import React from 'react'
import world from "../assets/about-page/about-world.png"
import Header from '../components/Header';
import Footer from '../components/Footer';

const Geoxon = () => {
    return (
        <>
            <Header />
            <div className='Landing'>
                <div className="Landing__hero">
                    <div className="container">
                        <h1>Geoxon™</h1>
                        <h2>Parcel and Delivery Services</h2>
                        <div className="Landing__description">
                            <p>
                                Geoxon facilitates and improves productivity in arranging for pickup, delivery, storage and transportation of documents, packages, freight and parcels via ground and air carriers.
                            </p>
                            <p>
                                Geoxon may improve service quality and productivity in postal services, parcel delivery, transportation of  articles and rental of mailboxes.
                            </p>
                        </div>
                        <a className="nav-button" href='mailto:hr@geoxon.com'>Contact Us</a>

                        <img className="Landing__world" src={world} alt="" />
                    </div>
                </div>
            </div>
            <Footer />
        </>

    );
}

export default Geoxon