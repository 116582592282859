const url = 'https://api.geoxon.com/services/info/api/search/'

export const search = async (gxnId) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ input: gxnId })
    }

    const response = await fetch(url, requestOptions)
    const data = await response.json()
    // const data = { "components": [{ "types": ["country"], "longName": "Turkey", "shortName": "TR" }, { "types": ["street_number"], "longName": "1", "shortName": "1" }, { "types": ["route"], "longName": "Yıldıran Sokak", "shortName": "Yıldıran Sokak" }, { "types": ["administrative_area_level_2"], "longName": "Üsküdar", "shortName": "Üsküdar" }, { "types": ["flat_number"], "longName": "2", "shortName": "2" }, { "types": ["administrative_area_level_4"], "longName": "Kısıklı", "shortName": "Kısıklı" }, { "types": ["postal_code"], "longName": "34692", "shortName": "34692" }, { "types": ["administrative_area_level_1"], "longName": "İstanbul", "shortName": "İstanbul" }], "location": { "xlon": 29.069214686751366, "ylat": 41.02262998501222 }, "sharingCode": "8981596707" }

    // const p =  new Promise((resolve, reject) => { resolve(data); })

    return data;
}

// export const searchBySet = async (gxnId, setResult) => {
//     const requestOptions = {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify({ input: gxnId })
//     }

//     // const response = await fetch(url, requestOptions)
//     // const data = await response.json()
//     const data = { "components": [{ "types": ["country"], "longName": "Turkey", "shortName": "TR" }, { "types": ["street_number"], "longName": "1", "shortName": "1" }, { "types": ["route"], "longName": "Yıldıran Sokak", "shortName": "Yıldıran Sokak" }, { "types": ["administrative_area_level_2"], "longName": "Üsküdar", "shortName": "Üsküdar" }, { "types": ["flat_number"], "longName": "2", "shortName": "2" }, { "types": ["administrative_area_level_4"], "longName": "Kısıklı", "shortName": "Kısıklı" }, { "types": ["postal_code"], "longName": "34692", "shortName": "34692" }, { "types": ["administrative_area_level_1"], "longName": "İstanbul", "shortName": "İstanbul" }], "location": { "xlon": 29.069214686751366, "ylat": 41.02262998501222 }, "sharingCode": "8981596707" }
    
//     if(setResult) {
//         setResult(data);
//     }
// }
