import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import "../styles/Landing.scss"
//import world from "../assets/about-page/about-world.png"
import customizable from "../assets/about-page/customizable.svg"
import universal from "../assets/about-page/universal.svg"
import accurate from "../assets/about-page/accurate.svg"
import safe from "../assets/about-page/safe.svg"
import adaptive from "../assets/about-page/adaptive.svg"
import footer from "../assets/about-page/about-footer.svg"

function AboutPage() {
  const data1 = [{
    icon: customizable,
    title: 'CUSTOMIZABLE',
    text: 'Geoxon is more than just an address. It encapsulates postall address, geographic coordinates and personal informations such as pictures, website, phone number, e-mail, socail media links, comments etc.'
  },
  {
    icon: universal,
    title: 'UNIVERSAL',
    text: 'Breaks the boundries between different languages and makes it universal. It unifies all different addressing formats in to a single ID.'
  },
  {
    icon: safe,
    title: 'SAFE',
    text: 'We take extra care with your pesonal information and NEVER share with 3rd parties. You decide who can see and access to your Geoxon IDs.'
  }
  ]
  const data2 = [{
    icon: accurate,
    title: 'ACCURATE',
    text: 'Geoxon provides accurate geographic coordinates with every ID that points out the intended place. No more hunting for the entrance door.'
  },
  {
    icon: adaptive,
    title: 'ADAPTIVE',
    text: "You don't have to inform anybody about your address changes anymore. Just change your Geoxon and you are good to go."
  }
  ]

  return (
    <>
      <Header />
      <div className="Landing">
        <div className="Landing__hero">
          <div className="container">
            <h1>What is Geoxon®?</h1>
            <div className="Landing__description">
              <p>
                Geoxon is a digital enriched-address and information sharing platform to enable utilization of all relevant information encoded under a short code in a secure and private manner. 
              </p>
              <p>
                You can create a customizable address for every point and object in the universe. It encapsulates all related information about address and serve as one.
              </p>
              <p>
                It makes sharing information easier and practical with a single ID that is easy to remember and suitable for verbal transfer. It replaces long archaic addresses and boring forms waiting to be filled with a single ID.
              </p>
              <p>
                Geoxon facilitates and improves productivity in arranging for pickup, delivery, storage and transportation of documents, packages, freight and parcels via ground and air carriers.
              </p>
              <p>
                Geoxon may improve service quality and productivity in postal services, parcel delivery, transportation of  articles and rental of mailboxes.
              </p>
            </div>
{/*             <Link className="nav-button" to='/'>Get Started Right Away</Link>
<img className="Landing__world" src={world} alt="" />
 */}
            
          </div>
        </div>
        <div className="Landing__benefits container">
          <div className="Landing__benefits__head">
            <h2>Benefits</h2>
            <p>Here are some reasons why you should have Geoxon.</p>
          </div>

          <div className="Landing__benefits__wrap">
            <div className="Landing__benefits__list">
              {data1.map(el => (
                <div className="Landing__benefits__item">
                  <img src={el.icon} alt="" />
                  <div className="Landing__benefits__item--content">
                    <h3>{el.title}</h3>
                    <p>{el.text}</p>
                  </div>
                </div>
              ))}
            </div>
            <div className="Landing__benefits__list">
              {data2.map(el => (
                <div className="Landing__benefits__item">
                  <img src={el.icon} alt="" />
                  <div className="Landing__benefits__item--content">
                    <h3>{el.title}</h3>
                    <p>{el.text}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="Landing__footer">
          <img src={footer} alt="" />
          <p>
            No matter which city you are living in,<br/>
            The universe is in the palm of your hand!
          </p>
        </div>
      </div>
      <Footer />

    </>
  );

}
export default AboutPage;