import React from 'react';
import './App.css';
import { Switch, Route } from 'react-router-dom';
import ErrorPage from './Views/ErrorPage';
import SearchView from './Views/SearchView';
import ResultView from './Views/ResultView';
import AboutPage from './Views/AboutPage';
import TrustedDelivery from './Views/TrustedDelivery';
import TrustedDeliveryNetwork from './Views/TrustedDeliveryNetwork';
import TrustedDeliveryLogistics from './Views/TrustedLogistics';
import TrustedDeliveryFarm from './Views/TrustedFarm';
import TrustedDeliveryAgent from './Views/TrustedDeliveryAgent';
import Geoxon from './Views/Geoxon';

class App extends React.Component {

  render() {
    return (
      <main>
        <Switch>
          <Route path="/" component={SearchView} exact />
          <Route path="/result" component={ResultView} exact />
          <Route path="/result/:gid" component={SearchView} />
          <Route path="/about-us" component={AboutPage} exact />
          <Route path="/services/geoxon" component={Geoxon} exact />
          <Route path="/services/trusted-delivery" component={TrustedDelivery} exact />
          <Route path="/services/trusted-delivery-agent" component={TrustedDeliveryAgent} exact />
          <Route path="/services/trusted-delivery-network" component={TrustedDeliveryNetwork} exact />
          <Route path="/services/trusted-farm" component={TrustedDeliveryFarm} exact />
          <Route path="/services/trusted-logistics" component={TrustedDeliveryLogistics} exact />
          <Route component={ErrorPage} />
        </Switch>
      </main>
    )
  }
}
export default App;