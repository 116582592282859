import React, { useState, useEffect } from "react";
import Map from '../components/Map';
import Header from '../components/Header';
import Footer from '../components/Footer';
import AddressIcon from '../assets/primary_1x.png'
import './views.css';
import './Result.css'
import { PhotoSwipeGallery } from 'react-photoswipe';
import 'react-photoswipe/lib/photoswipe.css';

function indexOfbyKey(obj_list, key, value) {
  for (const index in obj_list) {
    if ((obj_list[index][key].toString() === value) && (obj_list[index]['shortName'] !== "")) return index;
  }
  return -1;
}

function arrayRemove(arr, value) {
  return arr.filter(function (ele) {
    return ele !== value;
  });
}

const constructAddress = (address) => {
  const keys = ["flat_number", "street_number", "route", "administrative_area_level_4",
    "administrative_area_level_3", "locality", "administrative_area_level_2",
    "administrative_area_level_1", "postal_code", "country"]

  const keyIndex = []
  for (let k = 0; k < keys.length; k++) {
    keyIndex[k] = indexOfbyKey(address.components, 'types', keys[k].toString());
  }

  const order = arrayRemove(keyIndex, -1);



  // let addressStr = "";
  // if(address){

  //   for(let i = 0 ; i < order.length ; i++){
  //     let curStr = address.components[order[i]]?.shortName
  //     if(order[i] == 1){ curStr = "No: " + curStr +   " / " }
  //     else { curStr += ", " }
  //     addressStr = addressStr + curStr
  //   }
  //   return addressStr;
  // }
  // return addressStr; currentStr = "No: " + currentStr + " / " 

  //      if (address.components[order[index]]?.types.toString() === 'flat_number') { currentStr = "No: " + currentStr + " / " }

  if (address) {
    let addressStr = ''
    order?.forEach((_el, index) => {
      const currentStr = address.components[order[index]]?.shortName
      addressStr = addressStr + currentStr

      if (index !== order.length - 1) {
        if (currentStr.slice(-1) === '.') {
          addressStr = addressStr + ' '
        } else {
          addressStr = addressStr + ', '
        }
      }
    });
    return addressStr
  }
}

function ResultView(props) {
  ;
  const response = props.location.state?.response;
  const gxnId = response.sharingCode
  const { waypoints, photos } = response;



  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)

    return _ => {
      window.removeEventListener('resize', handleResize)
    }
  })
  const [witems, setWitems] = useState([])
  const [pitems, setPitems] = useState([])
  const [loadLightbox, setLoadLightbox] = useState(false)

  useEffect(() => {
    if (waypoints !== null && !loadLightbox) {
      setLoadLightbox(true)
      for (let i = 0; i < waypoints.length; i++) {
        setWitems(witems => [...witems, {
          src: getImageByFileId(waypoints[i].photo?.fileId),
          thumbnail: getImageByFileId(waypoints[i].photo?.fileId),
          w: 1920,
          h: 1080,
          title: waypoints[i]?.description,
          index: i
        }])
      }
    }

    if (photos !== null && !loadLightbox) {
      setLoadLightbox(true)
      for (let i = 0; i < photos.length; i++) {
        setPitems(pitems => [...pitems, {
          src: getImageByFileId(photos[i].fileId),
          thumbnail: getImageByFileId(photos[i].fileId),
          w: 1920,
          h: 1080,
          title: photos[i].description,
          index: i
        }])
      }
    }

  }, [waypoints, photos, loadLightbox]);



  const getImageByFileId = (fileId) => {
    if (fileId) {
      return ('https://api.geoxon.com/services/content/' + fileId)
    }
  }


  const contructNavUrl = () => {
    if (response.location) {
      const lon = response.location.xlon
      const lat = response.location.ylat
      return ('https://maps.apple.com/maps?daddr=' + lat + ',' + lon)
    }
  }

  const widthCoeff = windowWidth < 740 ? 8 / 10 : (windowWidth < 1400 ? 5 / 10 : 3.5 / 10)

  const mapWidth = windowWidth * widthCoeff
  const mapHeight = mapWidth * 9 / 16


  const WayChar = [
    'A',
    'B',
    'C',
  ];


  const getThumbnailContent = (item) => {
    return (

      <img key={'p' + item.index} className='imagescr' src={item.thumbnail} width={120} height={90} alt="" />

    );
  }

  const getWThumbnailContent = (item) => {
    return (
      <div key={'photosImageW' + item.index} style={{ backgroundImage: 'url(' + item.thumbnail + ')' }} className='photosImageW' >
        <div key={'resimKapsayici' + item.index} className="resimKapsayici">
          <img key={'w' + item.index} className='imagescr' width={120} height={90} alt="" />
          <p key={WayChar[item.index]} className="resimYazisi">{WayChar[item.index]}</p> </div></div>


    );
  }


  /* const listWayPoint = response?.waypoints?.map((way,index) =>
   <div key={'photosImageW'+index} className='photosImageW'>
     <div key={'resimKapsayici'+index} className="resimKapsayici">
     <img key={index} className='imagescr' src={getImageByFileId(way.photo?.fileId)} width="200px" alt="" />
     <p key={WayChar[index]} className="resimYazisi">{WayChar[index]}</p> </div>
  </div>
    );
 
    const listPhotos = response?.photos?.map((way,index) =>
    <div key={'photosImage'+index} className='photosImage'>
    <img key={index} className='imagescr' src={getImageByFileId(way.fileId)} width="200px" alt="" />
    </div>
     ); 
 
  */

  let optionsP = {
    children: 'a'
    //http://photoswipe.com/documentation/options.html
  };
  let optionsW = {
    children: 'b'
    //http://photoswipe.com/documentation/options.html
  };


  if (!(photos === [] || photos === null) && waypoints.length === 0 && (response.description === "" || response.description === null)) {
    return (
      <div className='page-container'>
        <Header />
        <div className='search-header py-2'>{gxnId}</div>
        <div className='content-container position-relative'>
          <div className='container-fluid text-center py-4'>
            <div className='map-container mb-4' style={{ width: mapWidth, height: mapHeight }}>
              <Map location={response.location} waypoints={response.waypoints} />
            </div>


            <div className="photosRoot mt-1" >
              <div className="photoRoot-wrapper" style={{ width: mapWidth }}>
                <div className="photoRoot"><h3 className="photoTittle mt-3">Address</h3></div>
                <div className='address-container mt-4 mb-4 d-block'>
                  <img className='address-icon me-1' src={AddressIcon} alt="address-icon" />
                  {constructAddress(response)}
                </div>

                <div className="photoRoot"><h3 className="photoTittle mt-3">Photos</h3></div>
                <PhotoSwipeGallery items={pitems} options={optionsP} thumbnailContent={getThumbnailContent} />

              </div>
            </div>

            <a className='nav-button-anchor' href={contructNavUrl()}>
              <button className='nav-button'>Start Navigation</button>
            </a>

          </div>
        </div>
        <Footer />
      </div>
    )
  } else if (!(photos === [] || photos === null) && waypoints.length !== 0 && (response.description === "" || response.description === null)) {
    return (
      <div className='page-container'>
        <Header />
        <div className='search-header py-2'>{gxnId}</div>
        <div className='content-container position-relative'>
          <div className='container-fluid text-center py-4'>
            <div className='map-container mb-4' style={{ width: mapWidth, height: mapHeight }}>
              <Map location={response.location} waypoints={response.waypoints} />
            </div>

            <div className="photosRoot mt-1" >
              <div className="photoRoot-wrapper" style={{ width: mapWidth }}>
                <div className="photoRoot"><h3 className="photoTittle mt-3">Address</h3></div>
                <div className='address-container mt-4 mb-4 d-block'>
                  <img className='address-icon me-1' src={AddressIcon} alt="address-icon" />
                  {constructAddress(response)}
                </div>

                <div className="photoRoot"><h3 className="photoTittle mt-3">Photos</h3></div>
                <PhotoSwipeGallery items={pitems} options={optionsP} thumbnailContent={getThumbnailContent} />
                <div className="photoRoot"><h3 className="photoTittle mt-3">Waypoints</h3></div>
                <PhotoSwipeGallery items={witems} options={optionsW} thumbnailContent={getWThumbnailContent} />
              </div>

            </div>

            <a className='nav-button-anchor' href={contructNavUrl()}>
              <button className='nav-button'>Start Navigation</button>
            </a>

          </div>
        </div>
        <Footer />
      </div>
    )
  }
  else if ((photos === [] || photos === null) && waypoints.length !== 0 && (response.description === "" || response.description === null)) {
    return (
      <div className='page-container'>
        <Header />
        <div className='search-header py-2'>{gxnId}</div>
        <div className='content-container position-relative'>
          <div className='container-fluid text-center py-4'>
            <div className='map-container mb-4' style={{ width: mapWidth, height: mapHeight }}>
              <Map location={response.location} waypoints={response.waypoints} />
            </div>

            <div className="photosRoot mt-1" >
              <div className="photoRoot-wrapper" style={{ width: mapWidth }}>
                <div className="photoRoot"><h3 className="photoTittle mt-3">Address</h3></div>
                <div className='address-container mt-4 mb-4 d-block'>
                  <img className='address-icon me-1' src={AddressIcon} alt="address-icon" />
                  {constructAddress(response)}
                </div>

                <div className="photoRoot"><h3 className="photoTittle mt-3">Waypoints</h3></div>
                <PhotoSwipeGallery items={witems} options={optionsW} thumbnailContent={getWThumbnailContent} />
              </div>

            </div>

            <a className='nav-button-anchor' href={contructNavUrl()}>
              <button className='nav-button'>Start Navigation</button>
            </a>

          </div>
        </div>
        <Footer />
      </div>
    )
  }
  else if (!(photos === [] || photos === null) && waypoints.length === 0 && !(response.description === "" || response.description === null)) {
    return (
      <div className='page-container'>
        <Header />
        <div className='search-header py-2'>{gxnId}</div>
        <div className='content-container position-relative'>
          <div className='container-fluid text-center py-4'>
            <div className='map-container mb-4' style={{ width: mapWidth, height: mapHeight }}>
              <Map location={response.location} waypoints={response.waypoints} />
            </div>


            <div className="photosRoot mt-1" >
              <div className="photoRoot-wrapper" style={{ width: mapWidth }}>
                <div className="photoRoot"><h3 className="photoTittle mt-3">Address</h3></div>
                <div className='address-container mt-4 mb-4 d-block'>
                  <img className='address-icon me-1' src={AddressIcon} alt="address-icon" />
                  {constructAddress(response)}
                </div>

                <div className="photoRoot"><h3 className="photoTittle mt-3">Description</h3></div>
                <div className='address-container mb-4 d-block mw-100 mt-3'>
                  {response.description}
                </div>

                <div className="photoRoot"><h3 className="photoTittle mt-3">Photos</h3></div>
                <PhotoSwipeGallery items={pitems} options={optionsP} thumbnailContent={getThumbnailContent} />

              </div>

            </div>

            <a className='nav-button-anchor' href={contructNavUrl()}>
              <button className='nav-button'>Start Navigation</button>
            </a>

          </div>
        </div>
        <Footer />
      </div>
    )
  }
  else if ((photos === [] || photos === null) && waypoints.length !== 0 && !(response.description === "" || response.description === null)) {
    return (
      <div className='page-container'>
        <Header />
        <div className='search-header py-2'>{gxnId}</div>
        <div className='content-container position-relative'>
          <div className='container-fluid text-center py-4'>
            <div className='map-container mb-4' style={{ width: mapWidth, height: mapHeight }}>
              <Map location={response.location} waypoints={response.waypoints} />
            </div>

            <div className="photosRoot mt-1" >
              <div className="photoRoot-wrapper" style={{ width: mapWidth }}>
                <div className="photoRoot"><h3 className="photoTittle mt-3">Address</h3></div>
                <div className='address-container mt-4 mb-4 d-block'>
                  <img className='address-icon me-1' src={AddressIcon} alt="address-icon" />
                  {constructAddress(response)}
                </div>

                <div className="photoRoot"><h3 className="photoTittle mt-3">Description</h3></div>
                <div className='address-container mb-4 d-block mw-100 mt-3'>
                  {response.description}
                </div>

                <div className="photoRoot"><h3 className="photoTittle mt-3">Waypoints</h3></div>
                <PhotoSwipeGallery items={witems} options={optionsW} thumbnailContent={getWThumbnailContent} />
              </div>
            </div>

            <a className='nav-button-anchor' href={contructNavUrl()}>
              <button className='nav-button'>Start Navigation</button>
            </a>

          </div>
        </div>
        <Footer />
      </div>
    )
  }
  else if ((photos === [] || photos === null) && waypoints.length === 0 && !(response.description === "" || response.description === null)) {
    return (
      <div className='page-container'>
        <Header />
        <div className='search-header py-2'>{gxnId}</div>
        <div className='content-container position-relative'>
          <div className='container-fluid text-center py-4'>
            <div className='map-container mb-4' style={{ width: mapWidth, height: mapHeight }}>
              <Map location={response.location} waypoints={response.waypoints} />
            </div>


            <div className="photosRoot mt-1" >
              <div className="photoRoot-wrapper" style={{ width: mapWidth }}>
                <div className="photoRoot"><h3 className="photoTittle mt-3">Address</h3></div>
                <div className='address-container mt-4 mb-4 d-block'>
                  <img className='address-icon me-1' src={AddressIcon} alt="address-icon" />
                  {constructAddress(response)}
                </div>

                <div className="photoRoot"><h3 className="photoTittle mt-3">Description</h3></div>
                <div className='address-container mb-4 d-block mw-100 mt-3'>
                  {response.description}
                </div>
              </div>
            </div>

            <a className='nav-button-anchor' href={contructNavUrl()}>
              <button className='nav-button'>Start Navigation</button>
            </a>

          </div>
        </div>
        <Footer />
      </div>
    )
  }
  else if ((photos === [] || photos === null) && waypoints.length === 0 && (response.description === "" || response.description === null)) {
    return (
      <div className='page-container'>
        <Header />
        <div className='search-header py-2'>{gxnId}</div>
        <div className='content-container position-relative'>
          <div className='container-fluid text-center py-4'>
            <div className='map-container mb-4' style={{ width: mapWidth, height: mapHeight }}>
              <Map location={response.location} waypoints={response.waypoints} />
            </div>


            <div className="photosRoot mt-1" >
              <div className="photoRoot-wrapper" style={{ width: mapWidth }}>
                <div className="photoRoot"><h3 className="photoTittle mt-3">Address</h3></div>
                <div className='address-container mt-4 mb-4 d-block'>
                  <img className='address-icon me-1' src={AddressIcon} alt="address-icon" />
                  {constructAddress(response)}
                </div>
              </div>
            </div>

            <a className='nav-button-anchor' href={contructNavUrl()}>
              <button className='nav-button'>Start Navigation</button>
            </a>

          </div>
        </div>
        <Footer />
      </div>
    )
  }
  else {
    return (
      <div className='page-container'>
        <Header />
        <div className='search-header py-2'>{gxnId}</div>
        <div className='content-container position-relative'>
          <div className='container-fluid text-center py-4'>
            <div className='map-container mb-4' style={{ width: mapWidth, height: mapHeight }}>
              <Map location={response.location} waypoints={response.waypoints} />
            </div>


            <div className="photosRoot mt-1" >
              <div className="photoRoot-wrapper" style={{ width: mapWidth }}>
                <div className="photoRoot"><h3 className="photoTittle mt-3">Address</h3></div>
                <div className='address-container mt-4 mb-4 d-block'>
                  <img className='address-icon me-1' src={AddressIcon} alt="address-icon" />
                  {constructAddress(response)}
                </div>

                <div className="photoRoot"><h3 className="photoTittle mt-3">Description</h3></div>
                <div className='address-container mb-4 d-block mw-100 mt-3'>
                  {response.description}
                </div>

                <div className="photoRoot"><h3 className="photoTittle mt-3">Photos</h3></div>
                <PhotoSwipeGallery items={pitems} options={optionsP} thumbnailContent={getThumbnailContent} />
                <div className="photoRoot"><h3 className="photoTittle mt-3">Waypoints</h3></div>
                <PhotoSwipeGallery items={witems} options={optionsW} thumbnailContent={getWThumbnailContent} />

              </div>        
            </div>

            <a className='nav-button-anchor' href={contructNavUrl()}>
              <button className='nav-button'>Start Navigation</button>
            </a>
          </div>
        </div>
        <Footer />
      </div>
    )
  }


}
export default ResultView;